.loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 100px;
  perspective: 780px;
  margin: 0 auto;
}

.text {
  font-size: 20px;
  font-weight: 700;
  color: var(--color-text);
  text-align: center;
  z-index: 10;
  margin: 0 auto;
}

.load-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 50%;
}

.load-inner.load-one {
  left: 0%;
  top: 0%;
  border-bottom: 3px solid #5c5edc;
  animation: rotate1 2.15s linear infinite;
}

.load-inner.load-two {
  right: 0%;
  top: 0%;
  border-right: 3px solid var(--primary);
  animation: rotate2 2.15s 0.1s linear infinite;
}

.load-inner.load-three {
  right: 0%;
  bottom: 0%;
  border-top: 3px solid #3b82f6;
  animation: rotate3 2.15s 0.15s linear infinite;
}

@keyframes rotate1 {
  0% {
    transform: rotateX(45deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(45deg) rotateY(-45deg) rotateZ(360deg);
  }
}
@keyframes rotate2 {
  0% {
    transform: rotateX(45deg) rotateY(45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(45deg) rotateY(45deg) rotateZ(360deg);
  }
}
@keyframes rotate3 {
  0% {
    transform: rotateX(-60deg) rotateY(0deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(-60deg) rotateY(0deg) rotateZ(360deg);
  }
}
