/*=============== HEADER ===============*/
.header {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin: 0.75rem;
  margin-bottom: 0;
  background: var(--secondary);
  border-radius: var(--br-md);
  box-shadow: 2px 0 24px var(--shadow-color);
}

.header__container {
  width: 100%;
  height: var(--header-height);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 1.5rem;
  border-radius: var(--br-md);
  transition: background-color 0.4s;
}

.header__logo {
  display: inline-flex;
  align-items: center;
  column-gap: 0.25rem;
}

.header__logo img {
  width: 160px;
}

.header__logo i {
  font-size: 1.5rem;
  color: var(--first-color);
}

.header__logo span {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

.header__toggle {
  font-size: 1.5rem;
  color: var(--text-color);
  cursor: pointer;
}
