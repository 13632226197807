/*=============== VARIABLES CSS ===============*/

@media screen and (min-width: 1024px) {
  :root {
    --h2-font-size: 1.75rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

/*=============== LOGIN FORM ===============*/
.login-bg {
  background: var(--secondary-strong);
  background-image: url("../../assets/Login_decorations/Bg.png");
  background-size: cover;
  min-height: 100vh;
  box-shadow: var(--secondary) 0 0 0 500px;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0;
  height: 100%;
}

.login__content,
.login__form,
.login__inputs {
  display: grid;
}

.login__content {
  position: relative;
  height: 100vh;
  align-items: center;
}

.login__img {
  width: 200px;
  text-align: center;
  margin: 0 auto;
}

.login__form {
  position: relative;
  background-color: rgba(15, 0, 125, 0.12);
  border: 2.5px solid var(--slate-500);
  color: var(--text-color);
  margin-inline: 1.5rem;
  row-gap: 1.25rem;
  backdrop-filter: blur(20px);
  padding: 2rem;
  border-radius: 1rem;
  z-index: 1;
  overflow: hidden;
}

.login__form:before {
  content: "";
  width: 100px;
  height: 100px;
  background-color: var(--primary);
  position: absolute;
  box-shadow: 0 0 30px 50px var(--primary), 0 0 150px 100px var(--primary);
  border-radius: 50%;
  top: -100px;
  left: -100px;
  z-index: -1;
}

.login__form:after {
  content: "";
  width: 100px;
  height: 100px;
  position: absolute;
  background-color: var(--primary);
  box-shadow: 0 0 30px 50px var(--primary), 0 0 150px 100px var(--primary);
  border-radius: 50%;
  bottom: -100px;
  right: -100px;
  z-index: -1;
}

.login__title {
  color: var(--white);
  font-size: var(--h2-font-size);
  margin-bottom: 0.5rem;
}

.login__title span {
  color: var(--primary);
}

.login__description {
  font-size: var(--small-font-size);
  color: var(--white);
}

.login__input {
  width: 100%;
  padding: 1.2rem 0.85rem;
  border-radius: 50px;
  border: 2px solid var(--secondary);
  background-color: hsla(249, 100%, 64%, 0.136);
  color: var(--title-color);
  font-size: var(--smaller-font-size);
  font-weight: var(--font-medium);
  transition: border 0.4s;
  backdrop-filter: blur(10px);
  row-gap: 0.75rem;
  margin-bottom: 0.5rem;
}

.login__input::placeholder {
  color: hsl(244deg 24.13% 42.63% / 75%);
}

.login__input:focus,
.login__input:valid {
  border: 2px solid var(--gray-400);
}

.login__buttons {
  display: flex;
}

.login__button {
  width: 100%;
  border-radius: 50px !important;
  background: linear-gradient(90deg, var(--primary), #f441a5, #442fa4, #f45303);
  background-size: 400%;
  font-family: monospace;
  border-color: transparent;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.25);
  font-size: 1.4rem;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
  color: #fff;
  font-weight: var(--font-semi-bold);
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all ease 0.4s;
}

.login__button:hover {
  animation: animate 8s linear infinite;
}

@keyframes animate {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 400%;
  }
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 360px) {
  .login__buttons {
    flex-direction: column;
  }
}

/* For medium devices */
@media screen and (min-width: 576px) {
  .login__form {
    width: 550px;
    justify-self: center;
    z-index: 1;
  }
}

/* For large devices */
@media screen and (min-width: 1064px) {
  .login__container {
    height: 100vh;
    display: grid;
    place-items: center;
  }

  .login__content {
    width: 1024px;
    height: 600px;
  }

  .login__form {
    justify-self: center;
  }
}

@media screen and (min-width: 1200px) {
  .login__content {
    height: 700px;
  }

  .login__form {
    row-gap: 2rem;
    padding: 3rem;
    border-radius: 1.25rem;
  }
}
