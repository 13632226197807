body.light {
  --color-text: #333; /* Cor do texto no tema claro */
  --primary: #ff8500;
  --primary-strong: #bf5700;
  --secondary: #14145a; /* Cor secundária no tema claro */
  --bg: #f1f5f9; /* Cor de fundo geral para o body */
  --bg-default: #fff; /* Cor de fundo principal clara */
  --bg-secondary: #f8fafc; /* Cor de fundo secundária clara */
  --bg-tertiary: #f1f5f9; /* Cor terciária clara */
  --slate-300: #cbd5e1; /* Cor intermediária para "slate" */
}
