.progress-meta-container {
  position: relative;
  margin: 30px auto;
}

.progress-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--color-text);
  margin-top: 20px;
}

.progress-wrapper {
  position: relative;
  width: 100%;
  height: 40px;
  overflow: visible;
}

.progress-bar-meta .progress-bar {
  background-color: var(--secondary-500) !important;
  transition: width 0.6s ease-in-out;
  border-radius: 50px;
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--white);
}

.progress-percentage {
  position: absolute;
  top: 35%;
  left: 35px;
  transform: translate(-50%, -50%);
  color: var(--white);
  font-weight: bold;
  font-size: 1.1rem;
}

.progress-step-marker {
  position: absolute;
  top: 55%;
  transform: translate(-50%, -70%);
  z-index: 10;
}

.step-marker-dot {
  width: 40px;
  height: 40px;
  background-color: var(--secondary);
  border: 1px solid var(--white);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  font-weight: bold;
  color: var(--white);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.progress-step-marker.reached .step-marker-dot {
  background-color: var(--green-regular);
  box-shadow: var(--white) 0 0 0 2px;
}

.step-number {
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--white);
}

.step-balloon {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--bg-default);
  color: var(--color-text);
  padding: 8px 12px;
  border-radius: var(--br-md);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  font-size: 0.9rem;
  font-weight: bold;
  white-space: nowrap;
  animation: float 1.5s ease-in-out infinite;
  z-index: 20;
}

.step-balloon::after {
  content: "▼";
  color: var(--bg-default);
  position: absolute;
  left: 50%;
  top: 115%;
  transform: translateY(-50%);
  pointer-events: none;
}

@keyframes float {
  0%,
  100% {
    transform: translateX(-50%) translateY(-3px);
  }

  50% {
    transform: translateX(-50%) translateY(3px);
  }
}

.progress-status {
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--color-text);
}

.progress-step-marker.reached .step-marker-dot {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
