@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200..1000&display=swap");

/*==================== VARIABLES ====================*/
:root {
  /*==================== PRIMARY COLORS ====================*/
  --color-text: #020617;
  --color-text-secondary: #868998;
  --primary-opacity: #f4792c35;
  --primary-50: #ffe1c7;
  --primary-100: #ffd1a4;
  --primary-200: #ffc080;
  --primary-300: #ffaf5d;
  --primary-400: #ff9f3a;
  --primary-500: #fb8500;
  --primary-600: #e27800;
  --primary-700: #c96b00;
  --primary: #ff8500;
  --primary-strong: #bf5700;
  --primary-950: #8a3900;

  /*==================== SECONDARY COLORS ====================*/
  --secondary-opacity: #2c4cf435;
  --secondary-50: #b3dbff;
  --secondary-100: #80c3ff;
  --secondary-200: #4daaff;
  --secondary-300: #1a91ff;
  --secondary-400: #007bff;
  --secondary-500: #005bcf;
  --secondary-600: #0048a8;
  --secondary-700: #003682;
  --secondary: #14145a;
  --secondary-strong: #262d74;
  --secondary-950: #21276d;

  /*==================== SLATE (NEUTRAL COLORS) ====================*/
  --white: #fff;
  --black: #000;
  --slate-50: #f8fafc;
  --slate-100: #f1f5f9;
  --slate-200: #e2e8f0;
  --slate-300: #cbd5e1;
  --slate-400: #94a3b8;
  --slate-500: #64748b;
  --slate-600: #475569;
  --slate-700: #334155;
  --slate-800: #1e293b;
  --slate-900: #0f172a;
  --slate-950: #020617;

  /*==================== BACKGROUNDS ====================*/
  --bg: #fff;
  --bg-default: #f8fafc;
  --bg-secondary: #f1f5f9;
  --bg-tertiary: #f1f5f9;

  /*========== FONT and typography ==========*/
  --body-font: "Nunito Sans", system-ui;
  --normal-font-size: 0.938rem;
  --smaller-font-size: 0.75rem;
  --tiny-font-size: 0.75rem;

  /*========== Font weight ==========*/
  --font-regular: 400;
  --font-semi-bold: 600;

  /*========== Other colors ==========*/

  --red-regular: #f75a68;
  --red-strong: #f43242;

  --green-regular: #42b67f;
  --green-strong: #198754;

  --warnign: #ffc107;

  /*========== Box Shadow ==========*/
  --shadow-sidebar-header: hsla(228, 80%, 4%, 0.1);
  --shadow-inside: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;

  /*========== Border Radius Sizes ==========*/
  --br-sm: 0.25rem;
  /* Pequeno */
  --br-md: 0.5rem;
  /* Médio */
  --br-lg: 1rem;
  /* Grande */
  --br-full: 9999px;
  /* Borda completa (círculos, etc.) */
}
