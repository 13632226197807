.quick-actions-bar {
  background: var(--slate-800);
  padding: 0.2rem;
  position: fixed;
  border: 1px solid var(--slate-300);
  margin: 0.5rem;
  border-radius: var(--br-md);
  width: auto;
  top: 0;
  left: 0;
  z-index: 10;
  gap: 8px;
  box-shadow: 2px 0 24px var(--shadow-color);
  transition: all ease 0.8s;
}

.quick-action-btn {
  color: var(--white);
  font-size: 1.5rem;
  transition: transform 0.2s ease-in-out;
}

.quick-action-btn:hover {
  color: var(--primary);
  transform: scale(1.1);
}

.quick-actions-bar {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.quick-actions-bar.action-bar-hidden:hover {
  opacity: 1;
}

.quick-actions-bar.action-bar-hidden {
  opacity: 0.1;
}
