/* ===== Calendar Container ===== */
.calendar-container {
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 0.5rem;
}

.highlight {
  background: var(--primary) !important;
}

/* ===== Mensagem do Dia ===== */
.calendar-message {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--white);
  background: var(--primary);
  padding: 0.5rem;
  border-radius: var(--br-md);
  margin-bottom: 20px;
}

/* ===== Grid de Dias ===== */
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

/* ===== Dias do Calendário ===== */
.calendar-day {
  padding: 10px;
  background: var(--bg-default);
  border: 1px solid var(--slate-300);
  border-radius: var(--br-md);
  font-size: 1.4rem;
  color: var(--color-text);
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  border: var(--primary) 1px solid;
  position: relative;
}

.calendar-sem {
  padding: 10px;
  background: var(--slate-800);
  border-radius: 8px;
  font-size: 1.4rem;
  color: var(--white);
  font-family: monospace;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  position: relative;
}

/* ===== Estilos de Destaque ===== */
.calendar-day.highlight {
  color: #fff;
  font-weight: bold;
  border-color: var(--primary);
}

/* ===== Legenda de Dias Especiais ===== */
.calendar-legend {
  height: 100%;
  width: 100%;
  overflow: hidden;
  text-align: left;
  color: var(--color-text);
  position: relative;
}

/* ===== Emoji de Árvore de Natal ===== */
.calendar-legend::before {
  content: "🎉";
  position: absolute;
  font-size: 300px;
  color: rgba(0, 128, 0, 0.1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

/* ===== Títulos da Legenda ===== */
.calendar-legend h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-weight: bold;
}

/* ===== Limite de Legenda ===== */
.legend-limit {
  max-height: 400px;
  overflow: auto;
}

/* ===== Entrada de Aniversário e Especial ===== */
.calendar-legend p {
  font-size: 1rem;
  background-color: var(--bg-secondary);
  margin: 5px 0;
  padding: 8px;
  border-left: 4px solid;
  line-height: 1.5rem;
  font-weight: bold;
}

.birthday-entry {
  color: var(--primary);
  border-left: 4px solid var(--primary);
  padding-left: 8px;
}

.special-entry {
  color: var(--secondary-400);
  border-left: 4px solid var(--secondary-400);
  padding-left: 8px;
}

.birthday-message {
  font-size: 1.5rem;
  color: var(--white);
  background: linear-gradient(var(--primary), gold);
  box-shadow: 0 0 5px goldenrod;
  padding: 0.5rem;
  border-radius: var(--br-md);
  margin: 10px 0;
  text-align: center;
  position: relative; /* Necessário para posicionar os balões */
}

.birthday-message::before {
  content: "🎈"; /* Emoji de balão */
  font-size: 2rem;
  position: absolute;
  bottom: 0;
  animation: float 2s ease-in-out infinite; /* Animação de flutuação */
}

/* ===== Destaque de Aniversário ===== */
.highlight-birthday {
  background: linear-gradient(45deg, var(--primary), gold, var(--primary));
  color: white;
}

/* ===== Borda Colorida para Aniversários ===== */
.calendar-day.birthday {
  font-size: 1.2rem;
  font-weight: bold;
  animation: glow 2s infinite alternate;
  position: relative;
}

.calendar-day.special-day {
  border-color: var(--secondary-400);
  background-color: var(--secondary-400);
  color: white;
}

/* ===== Animações ===== */
.calendar-day.birthday::after {
  content: "🎉";
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 1.5rem;
  animation: bounce 1s infinite alternate;
}

/* ===== Cabeçalho do Calendário ===== */
.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background: var(--bg-default);
}

/* ===== Título do Cabeçalho ===== */
.calendar-header h1 {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0;
}

/* ===== Botões do Cabeçalho ===== */
.calendar-header button {
  background-color: var(--slate-800);
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.calendar-header button:hover {
  background-color: #0056b3;
}

.calendar-header button:focus {
  outline: none;
}

/* ===== Animações de Efeitos Visuais ===== */
@keyframes glow {
  0% {
    box-shadow: 0 0 5px #ffd700;
  }
  100% {
    box-shadow: 0 0 10px var(--primary);
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* Eleva o balão */
  }
  100% {
    transform: translateY(0); /* Retorna para a posição original */
  }
}

/* ===== Subtítulo do Calendário ===== */
.calendar-subtitle {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--primary);
  margin: 20px 0;
  text-align: center;
  margin-top: -10px;
  animation: fadeIn 1s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* ===== Confete Decorativo ===== */
.confetti-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 999;
}

.confetti {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: red;
  opacity: 1;
  transform: rotate(45deg);
  animation-name: confetti-fall, confetti-spin;
  animation-timing-function: ease-in-out, linear;
  animation-iteration-count: infinite;
}

@keyframes confetti-fall {
  0% {
    top: -10%;
    opacity: 1;
  }
  100% {
    top: 110%;
    opacity: 0;
  }
}

@keyframes confetti-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.confetti:nth-child(2n) {
  background-color: var(--primary);
}

.confetti:nth-child(3n) {
  background-color: var(--secondary);
}

.calendar-day.empty {
  visibility: hidden;
}

.month-navigation {
}
