.solicitations-home {
  background: var(--bg-default);
  /* Substitui o fundo branco */
  border-radius: 10px;
  padding: 1rem;
}

.accordion>.card>.card-header {
  margin: 0;
}

.solicitations-home h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--color-text);
  /* Substitui o texto preto */
  margin-bottom: 10px;
}

.solicitations-home p {
  font-size: 1.2rem;
  color: var(--color-text-secondary);
  /* Usar uma cor secundária do texto se disponível */
}

.solicitations-home .shadow-lg {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.solicitations-home .text-center {
  text-align: center;
}

.solicitations-home .mb-3 {
  margin-bottom: 20px;
}

.solicitations-home .mt-4 {
  margin-top: 20px;
}

.solicitations-home .mt-5 {
  margin-top: 50px;
}

.solicitations-home .d-flex {
  display: flex;
  align-items: center;
}

.solicitations-home .mr-2 {
  margin-right: 0.5rem;
}

.solicitations-home .ml-2 {
  margin-left: 0.5rem;
}

.solicitations-home .mr-3 {
  margin-right: 1rem;
}

.solicitations-home .card::before {
  content: "";
  position: absolute;
  bottom: 40%;
  left: -15px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--primary);
  transform: rotate(90deg);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.solicitations-home .card:hover::before {
  opacity: 1;
  /* Exibe a seta ao passar o mouse */
}

.solicitations-home .card {
  border-radius: 15px;
  position: relative;
  transition: transform 0.3s ease;
  border: none;
  overflow: visible;
  box-shadow: 0 0 0 black !important;
  background: var(--bg-default);
  /* Substitui o fundo branco */
}

.solicitations-home .card-header {
  background: var(--bg-secondary);
  /* Substitui o fundo branco do header */
  border-bottom: 1px solid var(--border-color);
  /* Substitui a cor de borda */
  padding: 15px 20px;
  color: var(--color-text);
  /* Adiciona cor de texto */
}

.solicitations-home .card-body {
  background: var(--bg-secondary);
  border-radius: var(--br-md);
  margin: 8px 0;
  padding: 20px;
}

.solicitations-home .btn {
  font-size: 1rem;
  padding: 8px 16px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.solicitations-home .btn-dark {
  background-color: var(--btn-dark-bg);
  /* Variável para o botão escuro */
  color: var(--btn-dark-text);
  /* Variável para o texto do botão escuro */
  border: none;
}

.solicitations-home .btn-dark:disabled {
  background-color: var(--disabled-bg);
  /* Usar uma variável para o estado desabilitado */
  cursor: not-allowed;
}

.accordion .card-header {
  transition: background-color 0.3s ease;
}

.accordion .card-header:hover {
  opacity: 0.9;
}