/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-text);
  margin: 0;
  padding: 0;
  font-weight: bold;
}

a {
  text-decoration: none;
}

button {
  all: unset;
}

/*=============== Tema light ===============*/
body {
  background-color: var(--bg);
  color: var(--color-text);
  transition: background-color ease 0.3s, color ease-out 0.3s;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  line-height: 1.6;
  overflow-x: hidden;
}

/*=============== GLOBAL CLASSES ===============*/

.h-100vh {
  min-height: 100vh;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*========== Containers ==========*/
.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

/*========== Cards ==========*/
.card {
  background: var(--bg-default);
  border-radius: var(--br-md) !important;
  position: relative;
  height: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.card-header {
  border: none;
  font-weight: var(--font-semi-bold);
  border-top-left-radius: var(--br-md) !important;
  border-top-right-radius: var(--br-md) !important;
  background-color: var(--bg-secondary);
}

.card-body {
  background: var(--bg-default);
  border-radius: var(--br-md) !important;
}

.card-footer {
  background: var(--bg-secondary);
  border-bottom-left-radius: var(--br-md) !important;
  border-bottom-right-radius: var(--br-md) !important;
}

/*========== Headings ==========*/
.heading-sm {
  font-size: 1rem;
  font-weight: var(--font-semi-bold);
}

.heading-md {
  font-size: 1.5rem;
  font-weight: var(--font-semi-bold);
}

.heading-lg {
  font-size: 2rem;
  font-weight: var(--font-semi-bold);
}

/*========== Buttons ==========*/
.btn {
  display: inline-block;
  padding: 8px 16px;
  font-weight: var(--font-semi-bold);
  font-size: var(--normal-font-size);
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border-radius: var(--br-md);
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  border: none;
}

.btn-primary {
  background-color: var(--primary);
  color: var(--slate-50);
}

.btn-primary:hover {
  background-color: var(--primary-700);
  border-color: var(--primary-700);
}

.btn-success {
  background-color: var(--green-regular);
  color: var(--slate-50);
}

.btn-secondary {
  background-color: var(--secondary);
  color: var(--slate-50);
}

.btn-danger {
  background-color: var(--red-regular);
  color: var(--slate-50);
}

.btn-secondary:hover {
  background-color: var(--secondary-700);
  border-color: var(--secondary-700);
}

.btn-outline {
  background-color: transparent;
  border: 1px solid var(--slate-300);
  color: var(--slate-900);
}

.btn-outline:hover {
  background-color: var(--slate-50);
}

/*========== Links ==========*/
.link {
  color: var(--color-primary);
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

/*========== Text Colors ==========*/
.text-primary {
  color: var(--primary) !important;
}

.text-secondary {
  color: var(--slate-400) !important;
}

/*========== Backgrounds ==========*/
.bg-default {
  background: var(--bg-default);
}

.bg-secondary {
  background: var(--bg-tertiary);
}

.bg-secondary-c {
  background-color: var(--bg-secondary); /* Cor secundária clara */
}

.bg-color-primary {
  background-color: var(--primary);
  color: var(--slate-50);
}

.bg-color-secondary {
  background-color: var(--secondary);
  color: var(--slate-50);
}

.bg-light {
  background-color: var(--slate-100);
  color: var(--slate-900);
}

.bg-dark {
  background-color: var(--slate-900);
  color: var(--slate-50);
}

/*========== Borders ==========*/
.border {
  border: 1px solid var(--slate-200);
}

.border-primary {
  border: 1px solid var(--primary);
}

.border-secondary {
  border: 1px solid var(--secondary);
}

.border-light {
  border: 1px solid var(--slate-50);
}

.border-dark {
  border: 1px solid var(--slate-900);
}

/*========== Shadows ==========*/
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-md {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

/*========== Other ==========*/

.filter-brightness {
  filter: brightness(1);
}

/*========== Animations & Transitions ==========*/
.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.slide-up {
  animation: slideUp 0.5s ease-in-out;
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.transition {
  transition: all 0.3s ease;
}

.financial-access {
  color: var(--primary);
  font-weight: bold;
  background-color: var(--primary-opacity);
  padding: 0.4rem;
  border-radius: var(--br-md);
}

/*========== Gap ==========*/

.gap-4 {
  gap: 8px;
}

/*========== Alterações de Classes ==========*/

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid var(--slate-300) !important;
}

.table {
  color: var(--color-text) !important;
  overflow: auto;
}

.table > tbody > tr:hover {
  background: var(--bg-tertiary);
}

.table > tbody > tr:nth-child(2n) {
  background: var(--bg-default);
}

.css-hvgpr7-control {
  background: var(--bg-secondary) !important;
  border-color: var(--slate-300) !important;
}

.badge-danger {
  background-color: #dc35452e;
  border-color: transparent #dc3545;
  border-style: solid;
  border-width: 2px;
  color: #f43242;
  color: var(--red-strong);
}

.badge-success {
  background-color: #28a74566;
  border-color: transparent #28a745;
  border-style: solid;
  border-width: 2px;
  color: #198754;
  color: var(--green-strong);
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 0;
  width: 125px;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.badge-warning {
  background: #ffc1074d;
  border-color: transparent #ffc107;
  border-style: solid;
  color: var(--color-text);
  border-width: 2px;
}

.page-link {
  background: var(--bg-secondary);
  border-color: var(--slate-300);
  color: var(--color-text);
  position: relative;
}

.page-item.active .page-link {
  background-color: var(--secondary);
  border-color: var(--secondary-opacity);
  animation-duration: 0.2s;
  animation-name: activeLink;
  animation-iteration-count: initial;
  z-index: 0 !important;
}

@keyframes activeLink {
  0% {
    transform: rotateY(360deg);
    background-color: var(--secondary);
  }

  100% {
    background-color: var(--secondary);
  }
}

.css-1uccc91-singleValue {
  color: var(--color-text) !important;
}

.interaction {
  transition: all ease 0.4s;
  cursor: pointer;
}

.interaction:hover {
  transform: scale(1.2);
}

.form-control {
  background-color: var(--bg-secondary) !important;
  border-color: var(--slate-300) !important;
}

.form-control:focus {
  color: var(--color-text);
}

button:focus {
  outline: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--primary-strong);
  border-color: var(--primary);
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: var(--primary-strong);
  border-color: var(--primary);
  box-shadow: 0 0 0 0.2rem var(--primary-opacity);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: var(--secondary-strong);
  border-color: var(--secondary);
}

.badge {
  font-size: 0.75rem;
}

.form-control {
  color: var(--color-text);
}

.modal-footer {
  border-bottom-left-radius: var(--br-md) !important;
  border-bottom-right-radius: var(--br-md) !important;
  background: var(--bg-secondary);
  border-color: var(--slate-300);
}

.modal-header {
  border-top-left-radius: var(--br-md) !important;
  border-top-right-radius: var(--br-md) !important;
  background: var(--bg-secondary);
  border-color: var(--slate-300);
}

.modal-content {
  border-radius: var(--br-md);
  background: var(--bg-default);
}

.card-header {
  font-size: 1.2rem !important;
}

.drop-file {
  border-style: dashed;
  border-color: var(--slate-300);
  cursor: pointer;
  margin: 0.5rem 0;
  background: var(--bg-secondary);
}

.react-datepicker {
  background-color: var(--bg-default);
  color: var(--color-text);
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name,
.react-datepicker__day {
  color: var(--color-text);
}

.react-datepicker__header {
  background-color: var(--bg-secondary);
  color: var(--color-text);
}

.invalid-custom {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.react-select__control {
  height: 40px;
}

.react-select__value-container {
  padding: 0 8px;
}

.swal2-popup {
  background: var(--bg-default);
}

.swal2-file,
.swal2-input,
.swal2-textarea {
  background-color: var(--bg-secondary);
  color: var(--color-text);
  border-color: var(--slate-300);
}

.swal2-title {
  color: var(--color-text) !important;
}

/* Estilização básica do select */
.custom-select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 2px solid var(--bg-secondary);
  background-color: var(--bg-default);
  color: var(--color-text);
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  appearance: none; /* Remove a seta padrão */
}

/* Seta personalizada do select */
.custom-select::after {
  content: "▼";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Foco do select */
.custom-select:focus {
  outline: none;
  border-color: var(--secondary);
  box-shadow: 0 0 0 2px var(--secondary);
}

/* Estilização dos options */
.custom-select option {
  padding: 10px;
  background-color: var(--bg-default);
  color: var(--color-text) !important;
  font-size: 16px;
}

/* Cor diferente para o option em hover ou selecionado */
.custom-select option:hover,
.custom-select option:checked {
  background-color: var(--bg-secondary);
  color: var(--color-text);
}

.swal2-content {
  color: var(--color-text-secondary);
}

.Toastify__toast {
  border-radius: var(--br-md) !important;
}

/* Toast personalizado */
.Toastify__toast--info {
  background-color: var(--secondary-500) !important;
  color: var(--white);
}

.Toastify__toast--success {
  background-color: var(--green-regular) !important;
  color: var(--white);
}

.Toastify__toast--error {
  background-color: var(--red-regular) !important;
  color: var(--white);
}

/* ======== Estilizando a Barra de Rolagem (Scrollbar) ======== */
::-webkit-scrollbar {
  width: 12px; /* Largura da barra de rolagem */
}

::-webkit-scrollbar-track {
  background-color: var(
    --bg-default
  ); /* Cor do fundo do track (fundo da barra de rolagem) */
}

::-webkit-scrollbar-thumb {
  background-color: var(
    --secondary
  ); /* Cor principal do thumb (parte que rola) */
  border-radius: var(--br-md);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary); /* Cor ao passar o mouse */
}

::-webkit-scrollbar-thumb:active {
  background-color: var(--slate-300); /* Cor ao clicar no scroll */
}

/* ======== Alterando o Scroll para Outras Áreas (Ex.: Cards, Containers) ======== */
.custom-scrollbar {
  scrollbar-color: #a28bfe #f1f5f9; /* Cor da barra e do fundo, para navegadores que suportam essa propriedade */
  scrollbar-width: thin; /* Define uma barra mais fina */
}

.font-bold {
  font-weight: bold;
}

tr {
  border: var(--slate-300) 1px solid !important;
}

.css-3unch-control {
  background: var(--bg-default) !important;
  color: var(--color-text) !important;
  border-color: var(--slate-300) !important;
}

.form-control {
  border-radius: var(--br-md) !important;
}

thead {
  background: var(--bg-secondary);
  border: none;
  color: var(--color-text) !important;
}

.table > tbody > tr > td {
  text-align: start;
  color: var(--color-text) !important;
}

.table-hover tbody tr:hover {
  color: var(--color-secondary);
  background: var(--bg-tertiary);
}

.close {
  color: var(--red-regular);
  text-shadow: 0 1px 0 var(--red-strong);
  font-size: 2rem;
}

.boxshadow-none {
  box-shadow: none !important;
}

.progress {
  overflow: visible !important;
  background: var(--slate-300) !important;
  border-radius: 50px;
  height: 30px;
}

hr {
  border-color: var(--slate-300) !important;
}

.dropdown-menu {
  background: var(--bg-default);
  border: 1px solid var(--slate-300);
}

.dropdown-item {
  border: 1px solid transparent;
  transition: all ease 0.4s;
  cursor: pointer;
  color: var(--color-text);
}

.dropdown-item:hover {
  border: 1px solid var(--slate-300);
  color: var(--color-text);
  transform: scale(1.05);
  background: var(--bg-secondary);
  border-radius: var(--br-sm);
}

.elXHmT {
  color: var(--color-text-secondary) !important;
}
