.top-sidebar .sidebar__sublink:hover {
  color: var(--primary);
}

.top-sidebar .sidebar__sublink {
  color: var(--color-text-secondary);
}

.top-sidebar .sidebar__link,
.top-sidebar .accordion-toggle {
  color: #cbd5e1;
  padding-right: 0.5rem;
}

.top-sidebar .sidebar {
  position: absolute !important;
  overflow: visible !important;
  top: 0 !important;
  background: var(--slate-900);
  color: var(--white) !important;
  border-radius: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  height: auto !important;
  padding: 16px 8px;
}

.top-sidebar .header {
  opacity: 0;
}

.top-sidebar .sidebar__container {
  flex-direction: row;
  justify-content: start;
}

.top-sidebar .sidebar__content {
  flex-direction: row;
}

.top-sidebar .quick-actions-bar {
  right: 0 !important;
  left: auto !important;
  flex-direction: column;
  border-radius: var(--br-md);
  border: 1px solid var(--slate-400);
  width: 60px;
  margin: 0.35rem;
}

.top-sidebar .sidebar__list,
.sidebar__actions {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.top-sidebar .sidebar__user {
  margin-top: 0;
  display: flex;
  align-items: center;
}

.top-sidebar .sidebar__info h3 {
  overflow: hidden;
  text-wrap: nowrap;
}

.top-sidebar .accordion-content {
  padding-left: 0;
  background: var(--bg-default);
  border-radius: var(--br-md);
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.17) 0px 5px 15px;
  border: var(--slate-300) 1px solid;
}

.top-sidebar .accordion-toggle:hover {
  background-color: transparent;
}

.top-sidebar .sidebar__title {
  padding-left: 3.2rem;
}

.top-sidebar .active-link::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
}
