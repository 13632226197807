.icon-profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 60px;
  height: 60px;
  padding: 0.5rem;
  background: var(--white);
  border-radius: var(--br-md);
}

.icon-decor-event {
  width: 90px !important;
  height: 90px !important;
  z-index: -1;
  left: -15px;
  top: -10px;
  position: absolute;
}

.profile-icon {
  cursor: pointer;
  z-index: 1;
}

.icon-img img {
  width: 40px;
}

.edit-dropdown .btn {
  position: absolute;
  bottom: -40px;
  right: -10px;
  font-size: 18px;
  padding: 4px !important;
}

.edit-dropdown .btn:focus,
.edit-dropdown .btn:active {
  box-shadow: none !important;
}

.edit-dropdown .dropdown-toggle::after {
  display: none;
  /* Remove a seta padrão do dropdown */
}
