footer {
  border-top: 1px solid var(--slate-300);
  padding: 10px 20px;
  position: relative;
  margin-top: 40px;
  text-align: center;
  background-color: transparent;
  color: var(--slate-400);
  display: flex;
  justify-content: center;
  width: 100%;
  background: var(--bg-default);
}

footer p {
  align-self: center;
}
