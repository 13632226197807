.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  box-shadow: none;
}

.fc-col-header-cell {
  background: var(--secondary);
  font-family: monospace;
  color: #fff;
}

.fc-daygrid-day-frame {
  overflow-x: hidden;
  transition: all linear 0.4s;
}

.fc-daygrid-day-frame:hover {
  cursor: pointer;
}

.fc-v-event .fc-event-main {
  color: var(--secondary);
}

.fc-daygrid-day-events,
.fc-v-event {
  border: none;
  border-left: 2px solid var(--primary);
  color: var(--secondary);
  margin: 4px 2px;
  padding: 0 2px;
  background-color: var(--bg-secondary);
  overflow: hidden;
}

.fc-daygrid-day-top a {
  border-radius: 50px;
  padding: 2px;
  width: 25px;
  height: 25px;
  line-height: 17px;
  text-align: center;
  background-color: var(--bg-secondary);
  font-family: monospace;
  font-weight: bold;
}

.fc .fc-toolbar.fc-header-toolbar {
  background: var(--bg-secondary);
  padding: 4px;
  border: 4px solid;
  border-color: transparent var(--secondary) transparent var(--secondary);
  display: flex;
  align-items: center;
}

.fc-button-primary {
  background-color: var(--secondary) !important;
}

.fc-button-active {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.fc-toolbar-title {
  color: var(--secondary);
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
}
