.fade-out {
  transform: scaleX(0);
  transition: all ease 0.4s;
}

.loader {
  text-align: center;
  color: var(--color-text);
  position: relative;
}

.logo-top {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  padding: 1rem;
}

.logo-top img {
  width: 220px;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--slate-950);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  /* Garantir que fique acima de tudo */
  opacity: 1;
  transition: all 0.4s ease;
  /* Adicionando transição suave */
}

.star-box {
  width: 200px;
  height: 200px;
  padding: 3px;
  left: -115px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.star {
  margin: auto;
  width: 20rem;
  height: 20rem;
  position: absolute;
}

.star-2 {
  margin: auto;
  width: 20rem;
  height: 20rem;
  position: absolute;
}

.star__stroke {
  --dur: 2s;
  animation: stroke1 var(--dur) linear infinite;
}

.star__stroke--2 {
  animation-name: stroke2;
  animation-delay: calc(var(--dur) / -2);
}

.star__stroke--3 {
  animation-name: stroke3;
  animation-direction: reverse;
}

.star__stroke--4 {
  animation-name: stroke4;
  animation-delay: calc(var(--dur) / -2);
  animation-direction: reverse;
}

@keyframes configure-clockwise {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes configure-xclockwise {
  0% {
    transform: rotate(45deg);
  }

  25% {
    transform: rotate(-45deg);
  }

  50% {
    transform: rotate(-135deg);
  }

  75% {
    transform: rotate(-225deg);
  }

  100% {
    transform: rotate(-315deg);
  }
}

@keyframes stroke1 {
  from {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: 124;
  }
}

@keyframes stroke2 {
  from {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: 248;
  }
}

@keyframes stroke3 {
  from {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: 372;
  }
}

@keyframes stroke4 {
  from {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: 496;
  }
}

.words {
  overflow: hidden;
  position: relative;
}

.words::after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    var(--bg-color) 10%,
    transparent 30%,
    transparent 70%,
    var(--bg-color) 90%
  );
  z-index: 20;
}

.loader-words {
  color: rgb(124, 124, 124);
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 25px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  bottom: 50px;
  z-index: 99;
}

.word {
  display: block;
  height: 100%;
  padding-left: 6px;
  color: #956afa;
  animation: spin_4991 4s infinite;
}

@keyframes spin_4991 {
  10% {
    -webkit-transform: translateY(-102%);
    transform: translateY(-102%);
  }

  25% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  35% {
    -webkit-transform: translateY(-202%);
    transform: translateY(-202%);
  }

  50% {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
  }

  60% {
    -webkit-transform: translateY(-302%);
    transform: translateY(-302%);
  }

  75% {
    -webkit-transform: translateY(-300%);
    transform: translateY(-300%);
  }

  85% {
    -webkit-transform: translateY(-402%);
    transform: translateY(-402%);
  }

  100% {
    -webkit-transform: translateY(-400%);
    transform: translateY(-400%);
  }
}
