/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 5.5rem;

  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --first-color: hsl(228, 85%, 63%);
  --title-color: hsl(228, 18%, 16%);
  --body-color: hsl(228, 100%, 99%);

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

/*========== Responsive typography ==========*/
@media screen and (min-width: 1150px) {
  :root {
    --normal-font-size: 1rem;
    --smaller-font-size: 0.813rem;
  }
}

.dark-theme .sidebar__content::-webkit-scrollbar {
  background-color: hsl(228, 16%, 30%);
}

.dark-theme .sidebar__content::-webkit-scrollbar-thumb {
  background-color: hsl(228, 16%, 40%);
}

/*=============== REUSABLE CSS CLASSES ===============*/
.container {
  margin-inline: 1.5rem;
}

.main {
  padding-top: 5rem;
}

/*=============== SIDEBAR ===============*/
.sidebar {
  position: sticky;
  top: 0;
  min-width: 120px;
  background: var(--slate-900);
  border-right: 3px solid var(--slate-900);
  height: 100vh;
  box-shadow: 2px 0 24px var(--shadow-color);
  padding-block: 1.5rem;
  transition: all 0.4s ease;
  z-index: 1;
}

.sidebar__container,
.sidebar__content {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
}

.sidebar__container {
  height: 100%;
  overflow: hidden;
}

.sidebar__user {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  grid-gap: 8px;
  padding-left: 1.25rem;
  font-weight: bold;
  font-family: monospace;
  margin-top: 50px;
}

.sidebar__info h3 {
  font-size: var(--normal-font-size);
  color: var(--white);
  margin-bottom: 8px;
  transition: color 0.4s;
  word-break: break-word;
  width: 160px;
}

.sidebar__info span {
  font-size: var(--smaller-font-size);
}

.sidebar__content {
  overflow: hidden auto;
}

.sidebar__content::-webkit-scrollbar {
  width: 0.4rem;
  background-color: hsl(228, 8%, 85%);
}

.sidebar__content::-webkit-scrollbar-thumb {
  background-color: hsl(228, 8%, 75%);
}

.sidebar__title {
  width: max-content;
  font-size: var(--tiny-font-size);
  color: var(--white);
  font-weight: var(--font-semi-bold);
  padding-left: 2rem;
  margin-bottom: 1.5rem;
}

.sidebar__list,
.sidebar__actions {
  display: grid;
  row-gap: 1.5rem;
}

.sidebar__link {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  column-gap: 1rem;
  color: var(--white);
  padding-left: 2rem;
  transition: color 0.4s, opacity 0.4s;
}

.sidebar__link i {
  font-size: 1.25rem;
}

.sidebar__link span {
  font-weight: var(--font-semi-bold);
}

.sidebar__link:hover {
  color: var(--primary);
}

.sidebar__actions {
  margin-top: auto;
}

.sidebar__actions button {
  cursor: pointer;
}

.sidebar__theme {
  width: 100%;
  font-size: 1.25rem;
}

.sidebar__theme span {
  font-size: var(--normal-font-size);
  font-family: var(--body-font);
}

/* Show sidebar */
.show-sidebar {
  left: 0;
}

/* Active link */
.active-link {
  color: var(--primary) !important;
}

.active-link::after {
  content: "";
  position: absolute;
  left: 0;
  width: 3px;
  height: 20px;
  background-color: var(--primary);
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 360px) {
  .header__container {
    padding-inline: 1rem;
  }

  body {
    margin: 0;
  }

  .sidebar {
    width: max-content;
  }

  .sidebar__info,
  .sidebar__link span {
    display: none;
  }

  .sidebar__user,
  .sidebar__list,
  .sidebar__actions {
    justify-content: center;
  }

  .sidebar__user,
  .sidebar__link {
    grid-template-columns: max-content;
  }

  .sidebar__user {
    padding: 0;
  }

  .sidebar__link {
    padding-inline: 2rem;
  }

  .sidebar__title {
    padding-inline: 0.5rem;
    margin-inline: auto;
  }
}

/* For large devices */
@media screen and (min-width: 1150px) {
  .header {
    margin: 1rem;
    transition: padding 0.4s;
  }

  .header__container {
    padding-inline: 2rem;
  }

  .header__logo {
    order: 1;
  }

  .sidebar {
    left: 0;
    width: 300px;
  }

  .sidebar__info,
  .sidebar__link span {
    transition: opacity 0.4s;
  }

  .sidebar__user,
  .sidebar__title {
    transition: padding 0.4s;
  }

  /* Reduce sidebar */
  .show-sidebar {
    width: 90px;
  }

  .show-sidebar .sidebar__user {
    padding-left: 0.5rem;
  }

  .show-sidebar .sidebar__title {
    padding-left: 0;
    margin-inline: auto;
  }

  .show-sidebar .sidebar__info,
  .show-sidebar .sidebar__link span {
    opacity: 0;
  }

  .main {
    padding-left: 340px;
    padding-top: 8rem;
    transition: padding 0.4s;
  }

  /* Add padding left */
  .left-pd {
    padding-left: 114px;
  }
}

.accordion-toggle {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, max-content);
  align-items: center;
  column-gap: 1rem;
  color: var(--white);
  padding-left: 2rem;
  width: 100%;
  transition: all 0.4s ease;
}

.accordion-toggle:hover {
  background-color: var(--primary);
  color: var(--white);
}

.accordion-content {
  padding-left: 20px;
}

.sidebar__sublink {
  display: block;
  padding: 8px 10px;
  text-decoration: none;
  color: var(--white);
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.sidebar__sublink:hover {
  color: var(--white);
}
